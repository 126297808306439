<!--
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-13 16:20:48
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-12-06 17:26:36
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\pages\roleList\index.vue
 * @Description: 
-->
<template>
	<div class="user">
		<div class="md-layout" v-loading="loading">

			<div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
				<md-card class="md-card-plain">
					<md-card-header class="card-header" md-alignment="center">
						<!-- <h4 class="title">用户列表</h4> -->
						<!-- <p class="category"> -->
						<md-button class="md-success" @click="addUser"><i class="el-icon-plus mr-5"></i>添加角色</md-button>
						<!-- </p> -->
					</md-card-header>
					<md-card-content class="card-content">
						<el-table :data="roleList">
							<el-table-column type="index" align="center" label="序号" width="100">
							</el-table-column>
							<el-table-column prop="name" label="角色名称" min-width="120">
							</el-table-column>
							<el-table-column prop="description" label="描述" min-width="220">
							</el-table-column>
							<!-- mobile -->
							<!-- <el-table-column prop="mobile" label="手机号码" min-width="120">
								<template slot-scope="scope">
									{{ scope.row.mobile || '-' }}
								</template>
							</el-table-column> -->
							<!-- <el-table-column prop="phone" label="工作电话" min-width="120">
								<template slot-scope="scope">
									{{ scope.row.phone || '-' }}
								</template>
							</el-table-column> -->
							<el-table-column prop="createTime" label="添加时间" min-width="120">
								<template slot-scope="scope">{{ formatDate(scope.row.createTime) }}</template>
							</el-table-column>
							<el-table-column prop="phone" label="是否启用" min-width="120">
								<template slot-scope="scope">
									<el-switch @change="(v) => changeStatus(v, scope.row)" v-model="scope.row.status"
										:active-value="1" :inactive-value="0" active-color="#13ce66"></el-switch>
								</template>
							</el-table-column>
							<el-table-column fixed="right" align="center" label="操作" width="240">
								<template slot-scope="scope">
									<el-button type="text" @click="editUser(scope.row)">
										编辑
									</el-button>
									<el-popconfirm @confirm="delUser(scope.row)" confirm-button-text='确认'
										cancel-button-text='取消' icon="el-icon-info" icon-color="red"
										:title="`确认永久删除 ${scope.row.name} 吗？`">
										<el-button slot="reference" class="del-user" type="text">删除</el-button>
									</el-popconfirm>
								</template>
							</el-table-column>
						</el-table>
						<el-pagination class="page" layout="total, prev, pager, next" :current-page="page.pageNum"
							@current-change="updatePagination" :page-size="page.pageSize" :total="page.total">
						</el-pagination>
						<!-- <pagination ref="pagination" :total="page.total" @nextPage="updatePagination" /> -->
						<!-- <el-pagination class="page" layout="total, prev, pager, next" :current-page="1"
							@current-change="updatePagination" :page-size="12" :total="1000">
						</el-pagination> -->
					</md-card-content>
				</md-card>
			</div>
		</div>
		<!-- 添加编辑用户 -->
		<el-dialog :title="dialogFormTitle" :visible.sync="dialogVisible" width="30%" :before-close="handleCloseForm">
			<el-form ref="dialogForm" :rules="formRules" :label-position="labelPosition" label-width="80px"
				:model="userForm">
				<el-form-item label="角色名称" prop="username">
					<el-input v-model="userForm.username" placeholder="请输入角色名称"></el-input>
				</el-form-item>
				<el-form-item label="角色描述" prop="nickName">
					<el-input v-model="userForm.nickName" placeholder="请输入描述"></el-input>
				</el-form-item>
				<!-- <el-form-item label="备注">
					<el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入内容"
						v-model="userForm.note">
					</el-input>
				</el-form-item> -->
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleCloseForm">取 消</el-button>
				<el-button v-loading="btnLoading" type="success" @click="subUser">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
// import { SimpleTable, OrderedTable } from "@/components";
import * as API from '@/api/role.js'
// import Pagination from '@/components/pagination/index.vue'
import { formatDate } from '@/utils/assist.js'
export default {
	components: {
		// Pagination
	},
	data () {
		return {
			roleInfo: [],
			formRules: {
				name: [
					{
						required: true,
						message: '请输入角色名称',
						trigger: 'blur'
					},
					{
						required: true,
						message: '请输入3到30位字符',
						min: 3,
						max: 30,
						trigger: 'blur'
					}
				],
				description: [
					{
						required: true,
						message: '请输入描述',
						trigger: 'blur'
					},
					{
						required: true,
						message: '请输入6到100位字符',
						min: 6,
						max: 100,
						trigger: 'blur'
					}
				],
			},
			labelPosition: 'rigin',
			userForm: {
				name: '',
				description: ''
				// password: ''
			},
			dialogVisible: false,
			roleDialogVisible: false,
			dialogFormTitle: '角色添加',
			tableHeaderColor: '',
			tableData: [],
			paginatedUsers: [],
			loading: true,
			btnLoading: false,
			page: {
				total: 0,
				pageNum: 1,
				pageSize: 10
			},
			roleList: [],
			currentUserId: '',
			workLimitList: []
		}
	},
	created () {
		// this.getUserList()
		this.getRoleList()
		// this.getWorkLimitList()
	},
	methods: {
		/**
		 * 获取工作条线
		 */
		async getWorkLimitList () {
			try {
				const data = await API.getWorkLimitList()

				if (data.code === 200) {
					this.workLimitList = data.data || []
				}
			} catch (error) {
				console.error(error)
			}
		},
		/**
		 * 修改用户状态
		 */
		async changeStatus (val, { id, nickName }) {
			try {
				const data = await API.updateStatus({ status: val, id })
				if (data.code === 200) {
					const message = `已成功${val === 1 ? '启用' : '关闭'} ${nickName} 用户！`
					this.$notify.success({
						title: '提示',
						message
					})
				}
			} catch (error) {
				console.error(error)
			}
		},
		/**
		 * 删除用户账号
		 */
		async delUser ({ id, name }) {
			try {
				const data = await API.deleteRole({ ids: [id] })

				if (data.code === 200) {
					this.$notify.success({
						message: `已成功删除 ${name} 角色！`,
						title: '提示'
					})
					this.page.pageNum = 1
					this.getRoleList()
				}
			} catch (error) {
				console.error('delUser: ', error)
			}
		},
		/**
		 * 获取角色集合
		 */
		async getRoleList () {
			try {
				this.loading = true
				const data = await API.getRoleList()

				if (data.code === 200) {
					this.roleList = data.data || []
					this.page.total = data.data.length
				}
				this.loading = false
			} catch (error) {
				this.loading = false
				console.error(error)
			}
		},
		async confirmRole () {
			try {
				const data = await API.updateRole({
					adminId: this.currentUserId,
					roleIds: this.roleInfo
				})

				if (data.code === 200) {
					this.$notify.success({
						message: `已成功分配角色！`,
						title: '提示'
					})
					this.roleDialogClose()
				}
			} catch (error) {
				console.error(error)
			}
		},
		async addUserApi () {
			try {
				this.btnLoading = true
				const data = await API.addUser({
					...this.userForm
				})
				if (data.code === 200) {
					this.handleCloseForm()
					this.page.pageNum = 1
					this.getUserList()
					this.$notify.success({
						title: '提示',
						message: '角色添加成功'
					})
				}
				this.btnLoading = false
			} catch (error) {
				this.btnLoading = false
				console.error(error)
			}
		},
		async updateUser () {
			try {
				this.btnLoading = true
				const data = await API.updateUser({
					...this.userForm,
					id: this.currentUserId,
					password: ''
				})
				if (data.code === 200) {
					this.handleCloseForm()
					this.page.pageNum = 1
					this.getUserList()
					this.$notify.success({
						title: '提示',
						message: '用户修改成功'
					})
				}
				this.btnLoading = false
			} catch (error) {
				this.btnLoading = false
				console.error(error)
			}
		},
		/**
		 * 获取指定用户角色
		 */
		async getUserRole () {
			try {
				const data = await API.getUserRole({
					id: this.currentUserId
				})
				if (data.code === 200 && data.data) {
					this.roleInfo = data.data.map(t => t.id)
				}
			} catch (error) {
				console.error(error)
			}
		},
		async getUserInfo () {
			try {
				const data = await API.getUserInfo({
					id: this.currentUserId
				})
				if (data.code === 200 && data.data) {
					// this.roleInfo = data.data.map(t => t.id)
					const { info, link } = data.data
					Object.keys(this.userForm).forEach(key => {
						if (info[key]) {
							this.userForm[key] = info[key]
						}
					})
					this.userForm.workLimits = link && link.map(v => v.id) || []
				}
			} catch (error) {
				console.error(error)
			}
		},
		/**
		 * 初始化密码
		 */
		async createPassword () {
			try {
				this.$notify.success({
					title: '提示',
					message: '初始化密码成功'
				})
			} catch (error) {
				console.error(error)
			}
		},
		subUser () {
			this.$refs.dialogForm.validate(validator => {
				if (!validator) return
				if (this.dialogFormTitle === '角色添加') {
					this.addUserApi()
				} else {
					this.updateUser()
				}
			})
		},
		/**
		 * 打开弹窗 分配角色权限
		 */
		editAuthorise ({ id }) {
			this.currentUserId = id
			this.getUserRole()
			this.roleDialogVisible = true
		},
		roleDialogClose () {
			this.roleInfo = []
			this.currentUserId = ''
			this.roleDialogVisible = false
		},
		// handleClose () { },
		addUser () {
			this.dialogFormTitle = '角色添加'
			this.dialogVisible = true
		},
		editUser ({ id }) {
			this.currentUserId = id
			this.dialogFormTitle = '角色编辑'
			this.getUserInfo()
			this.dialogVisible = true
		},
		/**
		 * 分页翻动
		 */
		updatePagination (page, pageSize, sort, sortOrder) {
			console.log('pagination has updated', page, pageSize, sort, sortOrder);
			this.page.pageNum = page
			this.getUserList()
		},
		formatDate (dateStr) {
			if (!dateStr) return '-'
			return formatDate(new Date(dateStr).getTime(), 'YY-MM-DD hh:mm')
		},
		handleCloseForm () {
			this.currentUserId = ''
			this.$refs.dialogForm.resetFields()
			// this.userForm.note = ''
			// this.userForm.password = ''
			this.dialogVisible = false
		}
	},
}
</script>

<style lang="scss" scoped>
.card-content {
	background-color: #fff;
}

.auth-select {
	width: 100%;
}

.page {
	margin-top: 20px;
	text-align: right;
}

.del-user {
	padding-left: 12px;
}

.card-header {
	text-align: right;
	background-color: #eee;
}
</style>