/*
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-29 23:35:28
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-12-06 17:24:22
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\api\role.js
 * @Description: 
 */
import Http from '@/utils/http.js'

export const getRoleList = () => {
	return Http({
		url: '/role/listAll',
		method: 'get',
	})
}

export const deleteRole = (params) => {
	return Http({
		url: '/role/delete',
		method: 'post',
		data: params
	})
}
